import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import {
  ForgotPassword,
  Signin,
  Signup,
  ValidateRecoveryPassword
} from '../screens';
import SigninEnvios from '../screens/signin/signin-envios';
import {
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  SIGN_UP_ROUTE,
  VALIDATE_PASSWORD_RECOVERY
} from './constants';

const AuthenticationRoutes = ({ target }) => {
  const { pathname, search } = useLocation();
  const urlRedirect = `${pathname}${search}`;
  const isTargetOne = target === 'one';

  return (
    <Switch>
      <Route
        exact
        path={LOGIN_ROUTE}
        component={isTargetOne ? SigninEnvios : Signin}
      />
      <Route
        path={VALIDATE_PASSWORD_RECOVERY}
        component={ValidateRecoveryPassword}
      />
      <Route path={SIGN_UP_ROUTE} component={Signup} />
      <Route path={FORGOT_PASSWORD_ROUTE} component={ForgotPassword} />
      <Redirect to={{ pathname: LOGIN_ROUTE, state: { from: urlRedirect } }} />
    </Switch>
  );
};
AuthenticationRoutes.propTypes = {
  target: PropTypes.string
};
AuthenticationRoutes.defaultProps = {
  target: ''
};
export default AuthenticationRoutes;
