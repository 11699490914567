import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import Form from 'view/templates/base-form';
import Submit from 'view/templates/base-form/submit/picture-icon';
import CameraIsBlocked from 'view/templates/base-form/video-permission-denied';
import { TEXTS_FORM, getCamera } from 'view/templates/base-form/constant';
import { useStyles, TEXTS } from '../constants';

const EditUploadForm = ({ onSubmit }) => {
  const [front, setFront] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const styles = useStyles();
  const canvas = React.createRef();
  const video = getCamera();
  const [title, setTitle] = useState(TEXTS_FORM.BLOCKED.TITLE_DEVICE_NOT_FOUND);
  const [subtitle, setSubtitle] = useState(
    TEXTS_FORM.BLOCKED.SUBTITLE_DEVICE_NOT_FOUND
  );

  useEffect(() => {
    const stopCamera = () =>
      video &&
      video.current &&
      video.current.srcObject &&
      video.current.srcObject.getTracks().forEach(track => track.stop());

    const facingMode = front ? 'user' : 'environment';
    const setCamera = async () => {
      video.current.srcObject = await navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode
          }
        })
        .catch(err => {
          if (err.message === 'Permission denied') {
            setTitle(TEXTS_FORM.BLOCKED.TITLE_PERMISSION_DENIED);
            setSubtitle(TEXTS_FORM.BLOCKED.SUBTITLE_PERMISSION_DENIED);
          }
          setModalIsOpen(true);
        });
    };

    const startCamera = () =>
      video && video.current ? setCamera() : setModalIsOpen(true);

    stopCamera();
    startCamera();

    return () => stopCamera();
  }, [video, front]);

  const toggleCam = () => setFront(!front);
  const takePicture = () => {
    canvas.current.width = video.current.videoWidth;
    canvas.current.height = video.current.videoHeight;
    canvas.current.getContext('2d').drawImage(video.current, 0, 0);
    canvas.current.toBlob(onSubmit, 'image/png');
  };

  return (
    <>
      <Form>
        <Box
          data-testid="edit-form"
          py={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <video
            className={`${styles.box} ${styles.profilePicture}`}
            ref={video}
            muted
            autoPlay
          />
          <canvas ref={canvas} style={{ display: 'none' }} />
          <Typography align="center" variant="subtitle2">
            <Box pt={4}>{TEXTS.EDIT.INFOS[0]}</Box>
            <Box pt={3}>{TEXTS.EDIT.INFOS[1]}</Box>
          </Typography>
        </Box>

        <Submit onClick={takePicture} onToggleCam={toggleCam} />
      </Form>
      {modalIsOpen && (
        <CameraIsBlocked
          modalIsOpen={modalIsOpen}
          title={title}
          subtitle={subtitle}
        />
      )}
    </>
  );
};

EditUploadForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default EditUploadForm;
