import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  cnaeDialog: {
    outline: 0,
    [theme.breakpoints.down('md')]: {
      marginTop: 'auto',
      margin: 0,
      width: '100%',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px'
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3)
    }
  },
  dialogButton: {
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      width: '60%'
    }
  }
}));

export default useStyles;
