const SIGN_IN_BUTTON_ID = 'sign-in-btn';
const APPLE_SIGN_IN_BUTTON_ID = 'apple-sign-in-btn';
const APPLE_PROVIDER = 'SignInWithApple';
const APPLE_ICON_ID = 'apple-icon-id';
const GOOGLE_SIGN_IN_BUTTON_ID = 'google-sign-in-btn';
const GOOGLE_PROVIDER = 'Google';
const GOOGLE_ICON_ID = 'google-icon-id';
const EMAIL_INPUT_ID = 'email-input-id';
const CREATE_ACCOUNT_BUTTON_ID = 'create-account-btn-id';
const LOADING_ID = 'loading-id';

const NOT_AUTHORIZED_INVALID_CREDENTIALS = 'Incorrect username or password.';
const MIGRATION_NOT_AUTHORIZED_INVALID_CREDENTIALS =
  'UserMigration failed with error Incorrect username or password..';
const NOT_AUTHORIZED_SHOULD_SIGN_IN_WITH_GOOGLE_ACCOUNT =
  'User is not authorized to get auth details.';

const SIGN_IN_ERRORS = {
  UserNotConfirmedException: {
    code: 'UserNotConfirmedException',
    name: 'UserNotConfirmedException'
  },
  PasswordResetRequiredException: {
    code: 'PasswordResetRequiredException',
    name: 'PasswordResetRequiredException'
  },
  NotAuthorizedException: {
    code: 'NotAuthorizedException',
    name: 'NotAuthorizedException',
    [NOT_AUTHORIZED_INVALID_CREDENTIALS]: 'invalidCredentials',
    [NOT_AUTHORIZED_SHOULD_SIGN_IN_WITH_GOOGLE_ACCOUNT]:
      'shouldSignInWithGoogleAccount'
  },
  UserNotFoundException: {
    code: 'UserNotFoundException',
    name: 'UserNotFoundException',
    [MIGRATION_NOT_AUTHORIZED_INVALID_CREDENTIALS]: 'invalidCredentials'
  },
  Error: {
    code: 'NetworkError',
    name: 'Error'
  }
};

const LOGGIOPS_EMAIL_REGEX = '^(?!.*@loggiops\\.com).*$';

export {
  CREATE_ACCOUNT_BUTTON_ID,
  EMAIL_INPUT_ID,
  APPLE_ICON_ID,
  APPLE_PROVIDER,
  APPLE_SIGN_IN_BUTTON_ID,
  GOOGLE_ICON_ID,
  GOOGLE_PROVIDER,
  GOOGLE_SIGN_IN_BUTTON_ID,
  LOADING_ID,
  NOT_AUTHORIZED_INVALID_CREDENTIALS,
  MIGRATION_NOT_AUTHORIZED_INVALID_CREDENTIALS,
  NOT_AUTHORIZED_SHOULD_SIGN_IN_WITH_GOOGLE_ACCOUNT,
  SIGN_IN_BUTTON_ID,
  SIGN_IN_ERRORS,
  LOGGIOPS_EMAIL_REGEX
};
