import { Box, Grid, Link } from '@material-ui/core';
import React from 'react';
import { colors } from '@loggi/mar';
import { FOOTER_TEXTS } from './constants';

/**
 * The OneTemplateFooter is the component responsible of presenting the elements
 * of copyright and Terms and conditions for users.
 * no params needed
 */
const OneTemplateFooter = () => {
  const urlRedirect = `${FOOTER_TEXTS.TERMS_URL}`;
  return (
    <>
      <Box m={1} p={0} data-testid="footer-component">
        <Grid container justify="center" alignContent="space-between">
          <Link
            sx={{ fontWeight: 'medium' }}
            href={urlRedirect}
            target="_blank"
          >
            {FOOTER_TEXTS.TERMS_DESCRIPTION}
          </Link>
        </Grid>
        <Grid container justify="center" alignContent="space-between">
          <Box color={colors.smoke[700]} fontSize="10px">
            {FOOTER_TEXTS.COPY_RIGHT_LOGGI}
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default OneTemplateFooter;
