import React from 'react';
import DriverAuthenticationRoutes, {
  SmsVerificationCodePage
} from '@loggi/driver-authentication';
import CreateAccountPage from 'view/pages/create-account';
import EmailVerificationPage from 'view/pages/email-verification';
import FinishCreateAccountPage from 'view/pages/finish-create-account';
import DocumentHubPage from 'view/pages/document-hub';
import DocumentHubWaitingPage from 'view/pages/document-hub/waiting';
import CnpjPage from 'view/pages/cnpj-form';
import LicensePlatePage from 'view/pages/license-plate-form';
import BankAccountPage from 'view/pages/bank-account-form';
import ProfilePicturePage from 'view/pages/profile-picture-form';
import CnhPage from 'view/pages/cnh-form';
import DriverModalPage from 'view/pages/driver-modal-form';
import UserRestorePage from 'user-restore';
import DriverGetDriverPage from 'driver-get-driver';

import CreateAccountMachine from 'machines/create-account.machine';
import EmailVerificationMachine from 'machines/email-verification.machine';
import FinishCreateAccountMachine from 'machines/finish-create-account.machine';
import DocumentHubMachine from 'machines/document-hub.machine';
import CnpjMachine from 'machines/cnpj.machine';
import LicensePlateMachine from 'machines/license-plate.machine';
import BankAccountMachine from 'machines/bank-account.machine';
import ProfilePictureMachine from 'machines/profile-picture.machine';
import CnhMachine from 'machines/cnh.machine';
import DriverModalMachine from 'machines/driver-modal.machine';
import UserRestoreMachine from 'machines/user-restore.machine';
import DriverGetDriverMachine from 'machines/driver-get-driver.machine';

export const paths = {
  root: '/',
  login: '/login',
  createAccount: '/criar_conta',
  verificationCode: '/validar_telefone',
  documents: {
    hub: '/documentos',
    yourDocuments: '/documentos/seus_documentos',
    cnpj: '/documentos/cnpj',
    licensePlate: '/documentos/placa',
    account: '/documentos/conta',
    profilePicture: '/documentos/foto',
    cnh: '/documentos/cnh',
    driverModal: '/documentos/driver-modal'
  },
  updateUser: '/update-user',
  driverGetDriver: '/codigo_dgd',
  validateEmail: '/validar_email',
  finishCreateAccount: '/iniciar-envio-documentos'
};

const PAGES = {
  ONBOARDING: {
    component: () => (
      <DriverAuthenticationRoutes
        createAccountPath={paths.createAccount}
        onSuccessLoginRedirectPath={paths.documents.hub}
      />
    ),
    path: paths.root
  },
  VERIFICATION_CODE: {
    component: () => (
      <SmsVerificationCodePage
        onSuccessLoginRedirectPath={paths.driverGetDriver}
        onGoBack={paths.createAccount}
      />
    ),
    path: paths.verificationCode
  },
  USER_RESTORE: {
    component: UserRestorePage,
    path: paths.updateUser,
    machine: { UserRestoreMachine }
  },
  CREATE_ACCOUNT: {
    component: CreateAccountPage,
    path: paths.createAccount,
    machine: { CreateAccountMachine }
  },
  DRIVER_GET_DRIVER: {
    component: DriverGetDriverPage,
    path: paths.driverGetDriver,
    machine: { DriverGetDriverMachine }
  },
  EMAIL_VERIFICATION: {
    component: EmailVerificationPage,
    path: paths.validateEmail,
    machine: { EmailVerificationMachine }
  },
  FINISH_CREATE_ACCOUNT: {
    component: FinishCreateAccountPage,
    path: paths.finishCreateAccount,
    machine: { FinishCreateAccountMachine }
  },
  DOCUMENT_HUB: {
    component: DocumentHubPage,
    path: paths.documents.hub,
    machine: { DocumentHubMachine },
    authenticated: true
  },
  DOCUMENT_HUB_WAITING: {
    component: DocumentHubWaitingPage,
    path: paths.documents.yourDocuments,
    authenticated: true
  },
  CNPJ: {
    component: CnpjPage,
    path: paths.documents.cnpj,
    machine: { CnpjMachine },
    authenticated: true
  },
  LICENSE_PLATE: {
    component: LicensePlatePage,
    path: paths.documents.licensePlate,
    machine: { LicensePlateMachine },
    authenticated: true
  },
  BANK_ACCOUNT: {
    component: BankAccountPage,
    path: paths.documents.account,
    machine: { BankAccountMachine },
    authenticated: true
  },
  PROFILE_PICTURE: {
    component: ProfilePicturePage,
    path: paths.documents.profilePicture,
    machine: { ProfilePictureMachine },
    authenticated: true
  },
  CNH: {
    component: CnhPage,
    path: paths.documents.cnh,
    machine: { CnhMachine },
    authenticated: true
  },
  DRIVER_MODAL: {
    component: DriverModalPage,
    path: paths.documents.driverModal,
    machine: { DriverModalMachine },
    authenticated: true
  }
};

export default PAGES;
