import React from 'react';
import PropTypes from 'prop-types';
import './i18nInitForTests';
import AuthenticationRoutes from './routes';

const App = ({ target }) => {
  return <AuthenticationRoutes target={target} />;
};

App.propTypes = {
  target: PropTypes.string
};
App.defaultProps = {
  target: ''
};
export default App;
