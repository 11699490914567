import { addBreadcrumb, captureException } from '@sentry/browser';
import { Auth, Hub } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react';
import { v4 as uuid4 } from 'uuid';
import {
  AWS_COGNITO_IMPERSONATION_ERRORS,
  AWS_CUSTOM_AUTH_CHALLENGE_NAME,
  AWS_CUSTOM_CHALLENGE_NEW_PASSWORD_REQUIRED,
  COGNITO_IU,
  EXPIRED_REFRESH_TOKEN_ERROR_CODE,
  USER_NOT_AUTHENTICATED_ERROR
} from '../constants';
import {
  AUTH_EVENT,
  CUSTOM_OAUTH_STATE,
  FAILURE_FETCH_AUTH_USER,
  FAILURE_FORGOT_PASSWORD,
  FAILURE_SEND_PASSWORD_RECOVERY,
  RESET_AUTH_ERROR,
  RESET_AUTH_USER,
  SIGN_IN_EVENT,
  SIGN_IN_FAILURE,
  SIGN_UP_FAILURE,
  START_COMPLETE_NEW_PASSWORD,
  START_FETCH_AUTH_USER,
  START_FORGOT_PASSWORD,
  START_SEND_PASSWORD_RECOVERY,
  START_SIGN_IN,
  START_SIGN_UP,
  SUCCESS_COMPLETE_NEW_PASSWORD,
  SUCCESS_FETCH_AUTH_USER,
  SUCCESS_FORGOT_PASSWORD,
  SUCCESS_SEND_PASSWORD_RECOVERY,
  SUCCESS_SIGN_IN,
  SUCCESS_SIGN_IN_REQUIRED_NEW_PASSWORD,
  SUCCESS_SIGN_UP
} from './constants';
import reducer from './reducer';

Auth.configure({
  mandatorySignIn: true,
  authenticationFlowType: 'USER_PASSWORD_AUTH'
});

export const AmplifyAuthProviderContext = createContext(null);

export const AmplifyAuthProvider = ({ children }) => {
  const initialState = {
    loading: true,
    error: null,
    authenticatedUser: null,
    isImpersonation: false,
    newUser: null,
    newPasswordRequired: false,
    hasCompletedNewPassword: false,
    passwordRecovery: {
      hasConfirmedNewPassword: false,
      hasSentForgotPassword: false
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [triggerFetchUser, setTriggerFetchUser] = useState();
  const [urlRedirect, setUrlRedirect] = useState();

  const { newUser, error } = state;

  const signIn = async (email, password, customState) => {
    setUrlRedirect(customState);
    dispatch({ type: START_SIGN_IN });
    return Auth.signIn(email, password)
      .then(user => {
        if (user.challengeName === AWS_CUSTOM_CHALLENGE_NEW_PASSWORD_REQUIRED) {
          return dispatch({
            type: SUCCESS_SIGN_IN_REQUIRED_NEW_PASSWORD,
            payload: {
              authenticatedUser: user
            }
          });
        }
        dispatch({ type: SUCCESS_SIGN_IN });
        return true;
      })
      .catch(e => {
        dispatch({ type: SIGN_IN_FAILURE, payload: { error: e } });
        return false;
      });
  };

  const completeNewPassword = useCallback(
    newPassword => {
      const { authenticatedUser } = state;

      dispatch({ type: START_COMPLETE_NEW_PASSWORD });
      return Auth.completeNewPassword(authenticatedUser, newPassword)
        .then(() => {
          dispatch({ type: SUCCESS_COMPLETE_NEW_PASSWORD });
        })
        .then(() => {
          dispatch({ type: SUCCESS_SIGN_IN });
        });
    },
    [state]
  );

  const _sendCustomChallenge = (user, impersonationData, showNotification) => {
    const {
      impersonatorIdToken,
      impersonatorUsername,
      impersonatedUser
    } = impersonationData;

    Auth.sendCustomChallengeAnswer(user, impersonatorIdToken)
      .then(() => {
        const impersonatorData = {
          username: impersonatorUsername,
          idToken: impersonatorIdToken
        };

        localStorage.setItem(COGNITO_IU, JSON.stringify(impersonatorData));
        setTriggerFetchUser(user?.signInUserSession);
      })
      .then(() => {
        showNotification();
      })
      .catch(e => {
        const isKnownError = AWS_COGNITO_IMPERSONATION_ERRORS[e.code];
        if (!isKnownError) {
          const errorData = {
            ...e,
            name: `Error on impersonating a user: ${e.name}`
          };
          addBreadcrumb({
            message: `${e.name}`,
            level: 'error',
            data: {
              impersonatedUser,
              impersonatorIdToken,
              impersonatorUsername
            },
            type: 'error'
          });
          captureException(errorData);
        }
        dispatch({ type: SIGN_IN_FAILURE, payload: { error: e } });
      });
  };

  const impersonationSignIn = useCallback(
    (impersonatedUserEmail, showNotification) => {
      Auth.currentSession().then(data => {
        const impersonatorUsername = data.getIdToken().payload.email;
        const impersonatorIdToken = data.getIdToken().jwtToken;
        dispatch({ type: START_SIGN_IN });
        Auth.signIn(impersonatedUserEmail)
          .then(user => {
            if (user.challengeName === AWS_CUSTOM_AUTH_CHALLENGE_NAME) {
              _sendCustomChallenge(
                user,
                {
                  impersonatorUsername,
                  impersonatorIdToken,
                  impersonatedUser: impersonatedUserEmail
                },
                showNotification
              );
            }
          })
          .catch(e => {
            dispatch({ type: SIGN_IN_FAILURE, payload: { error: e } });
          });
      });
    },
    []
  );

  const signUp = useCallback((email, name, password) => {
    dispatch({ type: START_SIGN_UP });
    const newUserObj = {
      attributes: {
        email,
        name
      },
      password,
      username: uuid4()
    };
    return Auth.signUp(newUserObj)
      .then(() => {
        dispatch({
          type: SUCCESS_SIGN_UP,
          payload: { newUser: { shouldSignInUser: true, email, password } }
        });
        return true;
      })
      .catch(e => {
        dispatch({ type: SIGN_UP_FAILURE, payload: { error: e } });
        return false;
      });
  }, []);

  const federatedSignIn = useCallback(async (provider, customState) => {
    dispatch({ type: START_SIGN_IN });
    try {
      await Auth.federatedSignIn({ provider, customState });
    } catch (e) {
      dispatch({ type: SIGN_IN_FAILURE, payload: { error: e } });
    }
  }, []);

  const forgotPassword = useCallback(email => {
    dispatch({ type: START_FORGOT_PASSWORD });
    return Auth.forgotPassword(email)
      .then(() => {
        dispatch({ type: SUCCESS_FORGOT_PASSWORD });
        return true;
      })
      .catch(e => {
        dispatch({ type: FAILURE_FORGOT_PASSWORD, payload: { error: e } });
        return false;
      });
  }, []);

  const sendPasswordRecovery = useCallback(
    (email, verificationCode, newPassword) => {
      dispatch({ type: START_SEND_PASSWORD_RECOVERY });
      return Auth.forgotPasswordSubmit(email, verificationCode, newPassword)
        .then(() => {
          dispatch({ type: SUCCESS_SEND_PASSWORD_RECOVERY });
          return true;
        })
        .catch(e => {
          dispatch({
            type: FAILURE_SEND_PASSWORD_RECOVERY,
            payload: { error: e }
          });
          return false;
        });
    },
    []
  );

  const clearStoredData = () => {
    localStorage.removeItem(COGNITO_IU);
  };

  const signOut = useCallback(async () => {
    await Auth.signOut();
    clearStoredData();
    dispatch({ type: RESET_AUTH_USER });
  }, []);

  const hasImpersonatorSignedIn = () =>
    Boolean(localStorage.getItem(COGNITO_IU));

  const getCurrentUserSession = useCallback(() => {
    dispatch({ type: START_FETCH_AUTH_USER });

    Auth.currentSession()
      .then(userData => {
        const { payload: user } = userData.getIdToken();
        dispatch({
          type: SUCCESS_FETCH_AUTH_USER,
          payload: {
            authenticatedUser: user,
            isImpersonation: hasImpersonatorSignedIn()
          }
        });
      })
      .catch(e => {
        if (
          e !== USER_NOT_AUTHENTICATED_ERROR &&
          e?.code !== EXPIRED_REFRESH_TOKEN_ERROR_CODE
        ) {
          addBreadcrumb({
            level: 'fatal',
            message: `${e?.message}`,
            type: 'error'
          });
          captureException(e);
        }

        clearStoredData();
        dispatch({ type: FAILURE_FETCH_AUTH_USER, payload: { error: e } });
      });
  }, []);

  const fetchCurrentUserFromCognito = useCallback(() => {
    dispatch({ type: START_FETCH_AUTH_USER });

    return Auth.currentAuthenticatedUser({
      /**
       * Optional, by default is false. If true, this call will send a request
       * to Cognito to get the latest user data and don't consume from local cache
       */
      bypassCache: true
    })
      .then(userData => {
        const { attributes: user } = userData;

        dispatch({
          type: SUCCESS_FETCH_AUTH_USER,
          payload: {
            authenticatedUser: user
          }
        });
      })
      .catch(e => {
        clearStoredData();
        dispatch({
          type: FAILURE_FETCH_AUTH_USER,
          payload: { error: e }
        });
      });
  }, []);

  const values = useMemo(
    () => ({
      state: { ...state, urlRedirect },
      signUp,
      signIn,
      federatedSignIn,
      forgotPassword,
      sendPasswordRecovery,
      setUrlRedirect,
      signOut,
      getCurrentUserSession,
      fetchCurrentUserFromCognito,
      impersonationSignIn,
      completeNewPassword
    }),
    [
      state,
      urlRedirect,
      signUp,
      federatedSignIn,
      forgotPassword,
      sendPasswordRecovery,
      setUrlRedirect,
      signOut,
      getCurrentUserSession,
      fetchCurrentUserFromCognito,
      impersonationSignIn,
      completeNewPassword
    ]
  );

  const onHubEvent = eventData => {
    const {
      payload: { event, data }
    } = eventData;

    switch (event) {
      case SIGN_IN_EVENT: {
        const { signInUserSession } = data;
        return setTriggerFetchUser(signInUserSession);
      }
      case CUSTOM_OAUTH_STATE: {
        return setUrlRedirect(data);
      }
      default:
        return null;
    }
  };

  useEffect(() => {
    Hub.listen(AUTH_EVENT, onHubEvent);

    getCurrentUserSession();

    return () => Hub.remove(AUTH_EVENT, onHubEvent);
  }, [triggerFetchUser, getCurrentUserSession]);

  useEffect(() => {
    if (newUser?.shouldSignInUser) {
      signIn(newUser.email, newUser.password);
    }
  }, [newUser]);

  useEffect(() => {
    dispatch({ type: RESET_AUTH_ERROR });
  }, [error]);

  return (
    <AmplifyAuthProviderContext.Provider value={values}>
      {children}
    </AmplifyAuthProviderContext.Provider>
  );
};

AmplifyAuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const errorMessage =
  '`useAmplifyAuth` hook must be used within a `AmplifyAuthProvider` component';

export const useAmplifyAuth = () => {
  const context = useContext(AmplifyAuthProviderContext);

  if (!context) {
    throw new Error(errorMessage);
  }

  return context;
};
