import React from 'react';
import PropTypes from 'prop-types';
import { useService } from '@xstate/react';
import { Interpreter } from 'xstate';
import { FORM_ACTIONS, FORM_STATE } from 'machines/utils/form';
import PAGES from 'routes/pages';

import LoadingTemplate from 'view/templates/loading';
import ErrorTemplate from 'view/templates/loading-error';
import SignupLayoutTemplate from 'view/templates/signup-layout';
import UserRestoreComponent from './user-restore.component';

const UserRestorePage = ({ context, history, isDesktop }) => {
  const { services } = context;
  const { UserRestoreMachine } = services;
  const [current, send] = useService(UserRestoreMachine);

  const submitting = current.matches(FORM_STATE.submitting);
  const invalid = current.matches(
    `${FORM_STATE.editing}.${FORM_STATE.invalid}`
  );
  const disableSubmit = invalid || submitting;

  if (
    current.matches(FORM_STATE.success) ||
    current.matches(FORM_STATE.failure)
  ) {
    history.push(PAGES.ONBOARDING.path);
    return <></>;
  }

  const handleChange = data => send(FORM_ACTIONS.change, data);
  const handleSubmit = event => {
    event.preventDefault();
    send(FORM_ACTIONS.submit);
  };

  const retry = () => send(FORM_ACTIONS.retry);

  const defaultComponent = () => (
    <UserRestoreComponent
      onChange={handleChange}
      onSubmit={handleSubmit}
      context={current.context}
      disableSubmit={disableSubmit}
    />
  );

  const page =
    {
      [FORM_STATE.loading]: () => <LoadingTemplate />,
      [FORM_STATE.error]: () => <ErrorTemplate onRetry={retry} />
    }[current.value] || defaultComponent;

  return <SignupLayoutTemplate isDesktop={isDesktop} page={page()} />;
};

UserRestorePage.propTypes = {
  context: PropTypes.shape({
    services: PropTypes.shape({
      UserRestoreMachine: PropTypes.instanceOf(Interpreter)
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  isDesktop: PropTypes.bool.isRequired
};

export default UserRestorePage;
