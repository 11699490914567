import {
  useAmplifyAuth,
  USER_NOT_AUTHENTICATED_ERROR
} from '@loggi/authentication-lib';
import { OneTemplateFooter } from '@loggi/components/src/one/template';
import Box from '@material-ui/core/Box';
import { Typography, Link } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PasswordInput from '../../components/password-input/password-input.component';
import ScreenHeader from '../../components/screen-header.component';
import { errorHandler, showNotificationMessage } from '../../service';
import { SIGN_IN_ERRORS } from '../signin/constants';
import { GoogleSignIn, AppleSignIn, Divider } from '../signin/signin-envios';
import { LOGIN_ROUTE } from '../../routes/constants';
import {
  CONTINUE_BUTTON_ID,
  EMAIL_INPUT_BUTTON_ID,
  LOADING_ID,
  NAME_INPUT_BUTTON_ID,
  SIGNUP_ERRORS
} from './constants';
import IllustrationSignupMobile from '../../assets/illustration-signup-mobile.svg';
import {
  signupValidationSchema,
  _functionGetErrorFeedback,
  _functionSignInErrorNotification,
  _functionShowDefaultErrorNotificationMessage
} from '../utils';

const initialValues = {
  email: '',
  name: '',
  password: ''
};

const useSignupFormStyle = makeStyles(({ palette, spacing }) => ({
  progress: {
    color: palette.common.white
  },
  backgroundMobile: {
    backgroundColor: 'white'
  },
  packageStatusImage: {
    marginTop: spacing(10),
    marginLeft: spacing(20)
  },
  textHeaderMobile: {
    align: 'flex-start',
    margingTop: spacing(-10),
    marginBottom: spacing(5),
    padding: spacing(3)
  },
  registeLink: {
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  headerMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

const SignupFormMobile = ({ showAppleSignIn, showGoogleSignIn }) => {
  const {
    signUp,
    state: { error: stateError }
  } = useAmplifyAuth();
  const styles = useSignupFormStyle();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['signupForm', 'signIn']);
  const [loading, setLoading] = useState(false);

  const signInErrorNotification = useCallback(
    signInError =>
      _functionSignInErrorNotification(signInError, errorText => t(errorText)),
    [t]
  );

  const onSubmitSignUp = (formValues, { setSubmitting }) => {
    setLoading(true);
    const { email, name, password } = formValues;

    setSubmitting(false);

    signUp(email.toLowerCase(), name.trim(), password);
  };

  const getErrorFeedback = useCallback(
    error =>
      _functionGetErrorFeedback(
        error,
        errorText => t(errorText),
        signInErrorNotification
      ),
    [signInErrorNotification, t]
  );

  const showDefaultErrorNotificationMessage = useCallback(
    error =>
      _functionShowDefaultErrorNotificationMessage(
        error,
        errorText => t(errorText),
        enqueueSnackbar
      ),
    [enqueueSnackbar, t]
  );

  useEffect(() => {
    if (stateError && stateError !== USER_NOT_AUTHENTICATED_ERROR) {
      const isKnownError = Boolean(
        SIGNUP_ERRORS[(stateError.message?.name)] ||
          SIGN_IN_ERRORS[(stateError.message?.name)]
      );

      if (isKnownError) {
        const errorFeedback = getErrorFeedback(stateError);
        showNotificationMessage(errorFeedback, 'error', enqueueSnackbar);
      } else {
        errorHandler(stateError);
        showDefaultErrorNotificationMessage(stateError);
      }

      setLoading(false);
    }
  }, [
    stateError,
    getErrorFeedback,
    enqueueSnackbar,
    t,
    showDefaultErrorNotificationMessage
  ]);

  const formik = useFormik({
    initialValues,
    onSubmit: onSubmitSignUp,
    validationSchema: signupValidationSchema
  });
  const {
    handleSubmit,
    setFieldTouched,
    handleChange,
    handleBlur,
    values,
    errors,
    touched
  } = formik;

  return (
    <Box className={styles.headerMobile} mt={{ xs: -5, sm: -15, md: -15 }}>
      <Box className={styles.textHeaderMobile}>
        <Box mt={2}>
          <ScreenHeader title={t('headerContent.first')} />
        </Box>
        <Box>
          <Typography variant="h4">
            <strong>{t('headerContent.second')}</strong>
          </Typography>
        </Box>
        <Box m={2}>
          <img
            src={IllustrationSignupMobile}
            alt="illustration signup"
            data-testid="mobile-img"
          />
        </Box>

        <Box mt={3}>
          <Typography>
            <strong>{t('headerContent.third')}</strong>
          </Typography>
          <Typography>
            <strong>{t('headerContent.fourth')}</strong>
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography> {t('headerContent.fifth')} </Typography>
          <Typography> {t('headerContent.sixth')} </Typography>
        </Box>
        <Box mt={3}>
          <Typography> {t('headerContent.seventh')} </Typography>
          <Typography> {t('headerContent.eighth')} </Typography>
          <Typography> {t('headerContent.ninth')} </Typography>
        </Box>
      </Box>

      <Box p={3}>
        <FormikProvider value={formik}>
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
            data-testid="sign-up-form"
          >
            <Box pt={2} width="100%" p={3}>
              <Button
                color="primary"
                disabled={loading}
                fullWidth
                variant="contained"
              >
                <Link href="#cadastrar" className={styles.registeLink}>
                  {!loading && t('buttons.signup')}
                </Link>
              </Button>
            </Box>

            <Box m={5}>
              <Box ml={-5}>
                <Typography variant="h4">
                  <strong>{t('signupForm.titleFirst')}</strong>
                </Typography>
                <Typography variant="h4">
                  <strong>{t('signupForm.titleSecond')}</strong>
                </Typography>

                <Box mt={1} mb={-4}>
                  <Typography id="cadastrar">
                    {t('signupForm.subtitleFirst')}
                  </Typography>
                  <Typography>{t('signupForm.subtitleSecond')}</Typography>
                </Box>
              </Box>
            </Box>
            <TextField
              id={NAME_INPUT_BUTTON_ID}
              margin="normal"
              name="name"
              onChange={handleChange}
              onBlur={() => setFieldTouched('name')}
              fullWidth
              label={t('inputsLabel.name')}
              variant="outlined"
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <TextField
              id={EMAIL_INPUT_BUTTON_ID}
              margin="normal"
              onChange={handleChange}
              onBlur={() => setFieldTouched('email')}
              name="email"
              fullWidth
              label={t('inputsLabel.email')}
              variant="outlined"
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <PasswordInput
              handleChange={handleChange}
              password={values.password}
              handleBlur={handleBlur}
              error={touched.password && errors.password}
              label={t('inputsLabel.password')}
            />
            <Box pt={2} width="100%">
              <Button
                color="primary"
                disabled={loading}
                data-testid={CONTINUE_BUTTON_ID}
                fullWidth
                variant="contained"
                type="submit"
              >
                {!loading && t('buttons.continue')}
                {loading && (
                  <CircularProgress
                    data-testid={LOADING_ID}
                    className={styles.progress}
                    size={26}
                  />
                )}
              </Button>
            </Box>
            {(showGoogleSignIn || showAppleSignIn) && <Divider />}
            {showGoogleSignIn && <GoogleSignIn />}
            {showAppleSignIn && <AppleSignIn />}
            <Box margin={2}>
              <Typography align="center" className={styles.haveAccountText}>
                {t('signupForm.alreadyRegistered')}
                <Link href={LOGIN_ROUTE}>
                  <styles className={styles.haveAccountText}>
                    {t('signupForm.loginLink')}
                  </styles>
                </Link>
              </Typography>
            </Box>
          </form>
        </FormikProvider>
        <Box mt={5}>
          <OneTemplateFooter />
        </Box>
      </Box>
    </Box>
  );
};
SignupFormMobile.propTypes = {
  showAppleSignIn: PropTypes.bool,
  showGoogleSignIn: PropTypes.bool
};

SignupFormMobile.defaultProps = {
  showAppleSignIn: true,
  showGoogleSignIn: true
};

export default SignupFormMobile;
