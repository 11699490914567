import * as Yup from 'yup';
import { SIGN_IN_FAILURE, SIGN_UP_FAILURE } from '@loggi/authentication-lib';
import PASSWORD_MIN_LENGTH from './constants';
import { SIGN_IN_ERRORS } from './signin/constants';
import { SIGNUP_ERRORS } from './signup/constants';
import { showNotificationMessage } from '../service';

// signupValidationSchema
export const signupValidationSchema = Yup.object().shape({
  name: Yup.string().required('Opa! Faltou seu nome completo.'),
  email: Yup.string()
    .required('Opa! Faltou seu e-mail.')
    .email('Eita! Digite um e-mail válido.'),
  password: Yup.string()
    .required('Opa! Faltou a senha.')
    .min(PASSWORD_MIN_LENGTH, 'Opa! A senha deve ter 8 caracteres.')
});

// signInErrorNotification
export const _functionSignInErrorNotification = (signInError, formatT) => {
  const { NotAuthorizedException } = SIGN_IN_ERRORS;

  if (signInError.code === NotAuthorizedException.code) {
    return NotAuthorizedException[signInError.message]
      ? formatT(
          `signIn:errorsMessages.NotAuthorizedException.${
            NotAuthorizedException[signInError.message]
          }`
        )
      : formatT('signIn:errorsMessages.NotAuthorizedException.fallbackMessage');
  }
  return formatT(
    `signIn:errorsMessages.${SIGN_IN_ERRORS[signInError.name].code}`
  );
};

// getErrorFeedback
export const _functionGetErrorFeedback = (
  error,
  formatT,
  signInErrorNotification
) => {
  if (error.event === SIGN_UP_FAILURE) {
    const signupErrorMessage = error.message.message;
    const signUpErrorCode = error.message.code;
    return signupErrorMessage.includes(SIGNUP_ERRORS[signUpErrorCode].message)
      ? formatT('signupForm:errorsMessages.LoggiUserAlreadyExists')
      : formatT(
          `signupForm:errorsMessages.${SIGNUP_ERRORS[error.message.name].code}`
        );
  }
  return signInErrorNotification(error.message);
};

// showDefaultErrorNotificationMessage
export const _functionShowDefaultErrorNotificationMessage = (
  error,
  formatT,
  enqueueSnackbar
) => {
  if (error.event === SIGN_IN_FAILURE) {
    return showNotificationMessage(
      formatT('signIn:errorsMessages.DefaultException'),
      'error',
      enqueueSnackbar
    );
  }
  return showNotificationMessage(
    formatT('signupForm:errorsMessages.DefaultException'),
    'error',
    enqueueSnackbar
  );
};

export default {
  signupValidationSchema,
  _functionGetErrorFeedback,
  _functionSignInErrorNotification,
  _functionShowDefaultErrorNotificationMessage
};
