import graphqlRequest from 'infra-operations/graphql-request';
import { storeCredentials } from 'infra-operations/storage/credential';

const requestCreateDriverMutation = `
  mutation CreateDriverMutation($name: String!, $email: String!, $cpf: String!, $citySlug: String!, $transportType: Int!, $mobileNumber: String!, $token: String!, $gcmId: String!, $deviceId: String, $pushToken: String) {
    createDriver(input: {
          name: $name,
          email: $email,
          cpf: $cpf,
          citySlug: $citySlug,
          transportType: $transportType,
          mobileNumber: $mobileNumber,
          token: $token,
          gcmId: $gcmId,
          deviceId: $deviceId,
          pushToken: $pushToken
      }) {
          isSuccessfully: success
          driver {
              pk
              firstName
              email
              loggiuserPtr {
                apiKey
              }
          }
          errors {
              message
          }
    }
  }
`;

export default function createDriver(variables) {
  return graphqlRequest({
    query: requestCreateDriverMutation,
    variables
  }).then(({ data }) => {
    const response = data.createDriver;

    if (response && response.isSuccessfully) {
      storeCredentials({
        apiKey: response.driver.loggiuserPtr.apiKey,
        email: response.driver.email
      });

      return response;
    }

    const errorResponse = { errors: response.errors };
    return Promise.reject(errorResponse);
  });
}
