const credentialKey = 'current_user';

const getCredentials = () => {
  const creadentials = localStorage.getItem(credentialKey);
  return JSON.parse(creadentials) || {};
};

const storeCredentials = credential => {
  localStorage.setItem(credentialKey, JSON.stringify(credential));
};

const removeCredentials = () => {
  localStorage.removeItem(credentialKey);
};

export { getCredentials, storeCredentials, removeCredentials };
