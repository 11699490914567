import React from 'react';
import { Machine, assign } from 'xstate';
import { colors } from '@loggi/mar';
import { CheckCircle, Error } from '@material-ui/icons';
import { isEmbeddedInDriverApp } from '@loggi/drivers-app-version';
import { loginCheck } from 'infra-operations/services/login-check';
import requestPhoneVerification from 'infra-operations/services/request-phone-verification';
import * as storage from 'infra-operations/storage/credential';
import createDriverMutation from 'infra-operations/services/create-driver';
import { removeMobilePhoneFormatter } from './utils/form/formatters/mobile-phone';
import isMobilePhoneValid from './utils/form/validators/mobile-phone';

export const phoneMachineTexts = {
  sendedSMS: 'SMS reenviado! Fique atento.'
};

export const phoneMachineStates = {
  editing: 'editing',
  resend: 'resend',
  notResendable: 'notResendable',
  resendable: 'resendable',
  resending: 'resending',
  validation: 'validation',
  valid: 'valid',
  invalid: 'invalid',
  loading: 'loading',
  finished: 'finished'
};

export const phoneMachineEvents = {
  change: 'CHANGE',
  submit: 'SUBMIT',
  resend: 'RESEND'
};

const resendSMSCode = context =>
  requestPhoneVerification({
    mobileNumber: removeMobilePhoneFormatter(context.driver.mobileNumber)
  });

const formSubmit = context => {
  let deviceId;
  let pushToken;

  if (isEmbeddedInDriverApp()) {
    deviceId = window.driverAppBridge?.getDeviceId?.();
    pushToken = window.driverAppBridge?.getPushToken?.();
  }

  return context.isLogin
    ? loginCheck({
        mobile_phone: removeMobilePhoneFormatter(context.driver.mobileNumber),
        token: context.verificationCode,
        cognito_token: context.cognito_token,
        cognito_email: context.cognito_email,
        device_id: deviceId,
        push_token: pushToken
      })
    : createDriverMutation({
        ...context.driver,
        mobileNumber: removeMobilePhoneFormatter(context.driver.mobileNumber),
        token: context.verificationCode,
        gcmId: '',
        deviceId,
        pushToken
      });
};

const infoValid = context =>
  context.verificationCode.length === 6 &&
  isMobilePhoneValid(context.driver.mobileNumber);

const infoInvalid = context => !infoValid(context);

export const actions = {
  checkDeviceError: assign((context, event) => {
    if (
      !Array.isArray(event.data?.errors) ||
      event.data?.errors[0] === undefined ||
      event.data?.code !== 401
    ) {
      return {};
    }

    let error = event.data.errors[0];
    if (error.code === undefined) {
      if (typeof error.message !== 'string') {
        return {};
      }
      error = JSON.parse(error.message.replace(/'/g, '"'));
    }

    const { driver } = context;
    const { code, email } = error;

    return {
      driver: { ...driver, email },
      errorDevice: code
    };
  }),
  mergeErrors: assign({
    errors: (_, event) => event.data.errors
  }),

  setErrorNotification: assign({
    notification: context => ({
      type: 'NOTIFICATION.SET',
      color: colors.red[500],
      startAdornment: <Error fontSize="large" />,
      message: context.errors.length
        ? context.errors[0].message
        : 'Falha no sistema. Tente de novo.'
    })
  }),

  sendSuccessSMS: assign({
    notification: () => ({
      type: 'NOTIFICATION.SET',
      color: colors.green[500],
      startAdornment: <CheckCircle fontSize="large" />,
      message: phoneMachineTexts.sendedSMS
    })
  }),

  setLogin: assign({
    isLogin: (_, { data }) => data
  }),

  cleanNotification: assign({
    notification: null
  }),

  setCognito: assign((_, { data }) => ({
    cognito_token: data.cognito_token,
    cognito_email: data.cognito_email
  })),

  setMobileNumber: assign(context => {
    const { driver } = context;
    const {
      name,
      email,
      cpf,
      citySlug,
      transportType,
      mobileNumber
    } = driver?.mobileNumber ? driver : storage.getCredentials();

    return {
      driver: {
        ...context.driver,
        name,
        email,
        cpf,
        citySlug,
        transportType,
        mobileNumber
      }
    };
  })
};

const phoneVerificationMachine = Machine(
  {
    id: 'phoneVerification',
    initial: phoneMachineStates.editing,
    context: {
      driver: {},
      userId: null,
      verificationCode: '',
      isLogin: false,
      cognito_token: null,
      cognito_email: null,
      errors: [],
      notification: null,
      errorDevice: null
    },
    states: {
      [phoneMachineStates.editing]: {
        type: 'parallel',
        entry: ['setMobileNumber'],
        on: {
          SETLOGIN: { actions: 'setLogin' },
          SETCOGNITO: { actions: 'setCognito' },
          CLEANNOTIFICATION: { actions: 'cleanNotification' },
          SUBMIT: { target: phoneMachineStates.loading, cond: 'infoValid' },
          CHANGE: {
            actions: assign({
              verificationCode: (_, event) => event.code
            })
          }
        },
        states: {
          [phoneMachineStates.resend]: {
            initial: phoneMachineStates.notResendable,
            states: {
              [phoneMachineStates.resendable]: {
                on: {
                  RESEND: phoneMachineStates.resending
                }
              },
              [phoneMachineStates.resending]: {
                invoke: {
                  id: 'resendSMSCode',
                  src: 'resendSMSCode',
                  onDone: {
                    target: phoneMachineStates.notResendable,
                    actions: ['sendSuccessSMS']
                  },
                  onError: {
                    target: phoneMachineStates.resendable,
                    actions: ['mergeErrors', 'setErrorNotification']
                  }
                }
              },
              [phoneMachineStates.notResendable]: {
                after: {
                  20000: phoneMachineStates.resendable
                }
              }
            }
          },
          [phoneMachineStates.validation]: {
            initial: phoneMachineStates.invalid,
            states: {
              [phoneMachineStates.invalid]: {
                on: {
                  '': [{ target: phoneMachineStates.valid, cond: 'infoValid' }]
                }
              },
              [phoneMachineStates.valid]: {
                on: {
                  '': [
                    { target: phoneMachineStates.invalid, cond: 'infoInvalid' }
                  ]
                }
              }
            }
          }
        }
      },
      [phoneMachineStates.loading]: {
        invoke: {
          id: 'formSubmit',
          src: 'formSubmit',
          onDone: {
            target: phoneMachineStates.finished
          },
          onError: {
            target: phoneMachineStates.editing,
            actions: ['checkDeviceError', 'mergeErrors', 'setErrorNotification']
          }
        }
      },
      [phoneMachineStates.finished]: {
        type: 'final'
      }
    }
  },
  {
    actions,
    guards: {
      infoValid,
      infoInvalid
    },
    services: {
      resendSMSCode,
      formSubmit
    }
  }
);

export default phoneVerificationMachine;
