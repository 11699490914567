/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  useAmplifyAuth,
  USER_NOT_AUTHENTICATED_ERROR
} from '@loggi/authentication-lib';
import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary
} from '@loggi/components/src/one/template';
import { Grid, SvgIcon } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { ReactComponent as AppleIcon } from '../../assets/apple-icon.svg';
import { ReactComponent as GoogleIcon } from '../../assets/google-icon.svg';
import ForgotPasswordLink from '../../components/forgot-password-link/forgot-password-link';
import PasswordInput from '../../components/password-input/password-input.component';
import ScreenHeader from '../../components/screen-header.component';
import { FORGOT_PASSWORD_ROUTE, SIGN_UP_ROUTE } from '../../routes/constants';
import { errorHandler, showNotificationMessage } from '../../service';
import {
  APPLE_ICON_ID,
  APPLE_PROVIDER,
  APPLE_SIGN_IN_BUTTON_ID,
  CREATE_ACCOUNT_BUTTON_ID,
  EMAIL_INPUT_ID,
  GOOGLE_ICON_ID,
  GOOGLE_PROVIDER,
  GOOGLE_SIGN_IN_BUTTON_ID,
  LOADING_ID,
  SIGN_IN_BUTTON_ID,
  SIGN_IN_ERRORS,
  LOGGIOPS_EMAIL_REGEX
} from './constants';
import useSignInStyle from './styles';

const EmailInput = ({ handleChange, handleBlur, email, error }) => {
  const styles = useSignInStyle();
  const { t } = useTranslation('signIn');

  return (
    <TextField
      className={styles.input}
      autoFocus
      error={Boolean(error)}
      name="email"
      helperText={error}
      value={email}
      id={EMAIL_INPUT_ID}
      label={t('email.label')}
      onChange={handleChange}
      onBlur={handleBlur}
      fullWidth
      variant="outlined"
      inputProps={{ autoCapitalize: 'none' }}
    />
  );
};

EmailInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  error: PropTypes.string
};

EmailInput.defaultProps = {
  error: ''
};

export const GoogleSignIn = () => {
  const { state } = useLocation();
  const { federatedSignIn } = useAmplifyAuth();
  const styles = useSignInStyle();
  const { t } = useTranslation('signIn');

  return (
    <Grid item xs={12}>
      <Button
        variant="contained"
        className={`${styles.btn} ${styles.federatedBtn}`}
        fullWidth
        data-testid={GOOGLE_SIGN_IN_BUTTON_ID}
        onClick={() => federatedSignIn(GOOGLE_PROVIDER, state?.from)}
      >
        <SvgIcon
          component={GoogleIcon}
          viewBox="0 0 600 476.6"
          id={GOOGLE_ICON_ID}
        />
        {t('googleSignInButton.text')}
      </Button>
    </Grid>
  );
};

export const AppleSignIn = () => {
  const { state } = useLocation();
  const { federatedSignIn } = useAmplifyAuth();
  const styles = useSignInStyle();
  const { t } = useTranslation('signIn');

  return (
    <Grid item xs={12}>
      <Button
        variant="contained"
        className={`${styles.btn} ${styles.federatedBtn}`}
        fullWidth
        data-testid={APPLE_SIGN_IN_BUTTON_ID}
        onClick={() => federatedSignIn(APPLE_PROVIDER, state?.from)}
      >
        <SvgIcon component={AppleIcon} id={APPLE_ICON_ID} />
        {t('appleSignInButton.text')}
      </Button>
    </Grid>
  );
};

const CreateAccount = () => {
  const styles = useSignInStyle();
  const { push } = useHistory();
  const { t } = useTranslation('signIn');

  return (
    <Grid item>
      <Typography variant="caption" className={styles.createAccountText}>
        {t('dontHaveAccount.message')}
      </Typography>
      <Link
        className={styles.createAccountText}
        component="button"
        variant="body2"
        data-testid={CREATE_ACCOUNT_BUTTON_ID}
        onClick={() => push(SIGN_UP_ROUTE)}
      >
        {t('createAccount.text')}
      </Link>
    </Grid>
  );
};

const Divider = () => {
  const styles = useSignInStyle();
  const { t } = useTranslation('signIn');

  return (
    <Grid item xs={8} className={styles.divider}>
      <Typography variant="h1" className={styles.dividerText}>
        <span className={styles.dividerLine}>{t('divider.text')}</span>
      </Typography>
    </Grid>
  );
};

const SignIn = ({
  showForgotPassword,
  showAppleSignIn,
  showGoogleSignIn,
  showCreateAccount,
  appVersionComponent
}) => {
  const styles = useSignInStyle();
  const {
    signIn,
    state: { error: stateError }
  } = useAmplifyAuth();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('signIn');

  const [isPasswordInputVisible, setIsPasswordInputVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const history = useHistory();
  const [userEmail, setUserEmail] = useState('');

  const emailSchemaObject = Yup.string()
    .required(t('emailIsRequired.message'))
    .email(t('invalidEmail.message'))
    .matches(RegExp(LOGGIOPS_EMAIL_REGEX), t('invalidLoggiOpsEmail.message'));

  const SIGN_IN_SCHEMA = Yup.object().shape({
    email: emailSchemaObject
  });

  const SIGN_IN_SCHEMA_PASSWORD_VISIBLE = Yup.object().shape({
    email: emailSchemaObject,
    password: Yup.string().required(t('passwordIsRequired.message'))
  });

  const errorNotification = useCallback(
    error => {
      const { NotAuthorizedException, UserNotFoundException } = SIGN_IN_ERRORS;
      const {
        message: { code, message, name }
      } = error;

      if (code === NotAuthorizedException.code) {
        return NotAuthorizedException[message]
          ? t(
              `signIn:errorsMessages.NotAuthorizedException.${
                NotAuthorizedException[message]
              }`
            )
          : t('signIn:errorsMessages.NotAuthorizedException.fallbackMessage');
      }
      if (code === UserNotFoundException.code) {
        return UserNotFoundException[message]
          ? t(
              `signIn:errorsMessages.UserNotFoundException.${
                UserNotFoundException[message]
              }`
            )
          : t('signIn:errorsMessages.UserNotFoundException.fallbackMessage');
      }

      return t(`signIn:errorsMessages.${SIGN_IN_ERRORS[name].code}`);
    },
    [t]
  );

  const signInErrorHandler = signInError => {
    const {
      message: { code, name }
    } = signInError;
    const isKnownError = Boolean(SIGN_IN_ERRORS[name]);

    if (!isKnownError) {
      errorHandler(signInError);
      showNotificationMessage(
        t('signIn:errorsMessages.DefaultException'),
        'error',
        enqueueSnackbar
      );
      setLoading(false);
      return setUserEmail('');
    }

    const { PasswordResetRequiredException } = SIGN_IN_ERRORS;
    if (code === PasswordResetRequiredException.code) {
      return history.push({
        pathname: FORGOT_PASSWORD_ROUTE,
        state: {
          email: userEmail,
          translationNamespace: 'resetPasswordRequiredForm'
        }
      });
    }

    const errorFeedback = errorNotification(signInError);
    showNotificationMessage(errorFeedback, 'error', enqueueSnackbar);
    setLoading(false);
    return setUserEmail('');
  };

  useEffect(() => {
    if (state?.invalidSignUp) {
      showNotificationMessage(
        t('signUpUserAlreadyExistsError.message'),
        'error',
        enqueueSnackbar
      );
      history.replace(history.location.pathname, { invalidSignUp: false });
    }
  }, [state, enqueueSnackbar, t, history]);

  useEffect(() => {
    if (
      stateError &&
      stateError !== USER_NOT_AUTHENTICATED_ERROR &&
      userEmail
    ) {
      signInErrorHandler(stateError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateError, userEmail]);

  const validateAccount = formValues => {
    const { email, password } = formValues;

    if (email && password) {
      /**
       * We don't redirect to /home here because App.js take
       * care of the route logic for us.
       */
      setLoading(true);
      setUserEmail(email);
      signIn(email, password, state?.from);
    } else if (email) {
      setIsPasswordInputVisible(true);
    }
  };

  const handleFillPassword = e => {
    if (e.target.value) {
      setIsPasswordInputVisible(true);
    }
  };

  return (
    <OneTemplate>
      <OneTemplateSummary>
        <ScreenHeader
          title={t('header.message')}
          subTitle={t('accessAccount.message')}
        />
      </OneTemplateSummary>
      <OneTemplateContent>
        <Formik
          validateOnBlur={false}
          initialValues={{
            email: '',
            password: ''
          }}
          onSubmit={validateAccount}
          validationSchema={
            isPasswordInputVisible
              ? SIGN_IN_SCHEMA_PASSWORD_VISIBLE
              : SIGN_IN_SCHEMA
          }
        >
          {({
            handleChange,
            handleSubmit,
            handleBlur,
            values,
            errors,
            touched
          }) => (
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Grid container justify="center" alignContent="space-between">
                <EmailInput
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  email={values.email}
                  error={touched.email && errors.email}
                />
                <Box
                  width="100%"
                  display={isPasswordInputVisible ? 'block' : 'none'}
                >
                  <PasswordInput
                    handleChange={handleChange}
                    password={values.password}
                    handleBlur={handleBlur}
                    error={touched.password && errors.password}
                    handleFillPassword={handleFillPassword}
                    label={t('password.label')}
                  />
                  {showForgotPassword && (
                    <ForgotPasswordLink email={values.email} />
                  )}
                </Box>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    type="submit"
                    className={styles.btn}
                    fullWidth
                    data-testid={SIGN_IN_BUTTON_ID}
                  >
                    {!loading && t('signInButton.text')}
                    {loading && (
                      <CircularProgress
                        data-testid={LOADING_ID}
                        className={styles.loading}
                        size={26}
                      />
                    )}
                  </Button>
                </Grid>
                {(showGoogleSignIn || showAppleSignIn) && <Divider />}
                {showGoogleSignIn && <GoogleSignIn />}
                {showAppleSignIn && <AppleSignIn />}
                {showCreateAccount && <CreateAccount />}
                {appVersionComponent}
              </Grid>
            </form>
          )}
        </Formik>
      </OneTemplateContent>
    </OneTemplate>
  );
};

SignIn.propTypes = {
  showForgotPassword: PropTypes.bool,
  showAppleSignIn: PropTypes.bool,
  showGoogleSignIn: PropTypes.bool,
  showCreateAccount: PropTypes.bool,
  appVersionComponent: PropTypes.element
};

SignIn.defaultProps = {
  showForgotPassword: true,
  showAppleSignIn: true,
  showGoogleSignIn: true,
  showCreateAccount: true,
  appVersionComponent: null
};

export default SignIn;
