const LOGGIUSER_ALREADY_EXISTS_ERROR_MSG =
  'There is already a LoggiUser with this email';
const SIGNUP_ERRORS = {
  InvalidParameterException: {
    code: 'InvalidParameterException',
    name: 'InvalidParameterException'
  },
  UserLambdaValidationException: {
    code: 'UserLambdaValidationException',
    name: 'UserLambdaValidationException',
    message: LOGGIUSER_ALREADY_EXISTS_ERROR_MSG
  },
  InvalidPasswordException: {
    code: 'InvalidPasswordException',
    name: 'InvalidPasswordException'
  },
  Error: {
    code: 'NetworkError',
    name: 'Error'
  }
};

const CONTINUE_BUTTON_ID = 'submit-btn';
const EMAIL_INPUT_BUTTON_ID = 'email-input';
const LOADING_ID = 'loading-id';
const NAME_INPUT_BUTTON_ID = 'name-input';
const PASSWORD_BUTTON_ADORNIMENT_ID = 'password-adornment';

export {
  CONTINUE_BUTTON_ID,
  EMAIL_INPUT_BUTTON_ID,
  SIGNUP_ERRORS,
  LOADING_ID,
  NAME_INPUT_BUTTON_ID,
  PASSWORD_BUTTON_ADORNIMENT_ID,
  LOGGIUSER_ALREADY_EXISTS_ERROR_MSG
};
