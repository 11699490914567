import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

import 'typeface-open-sans';
import * as overrides from './overrides';
import { MuiAlertProps } from './overrides/mui-alert';
import { MuiDialogProps, MuiDialogTitleProps } from './overrides/mui-dialog';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import { pxToRem } from './utils';

const theme = createMuiTheme({
  palette,
  typography,
  shadows,
  shape: {
    borderRadius: pxToRem(8)
  },
  overrides: {
    ...overrides
  },
  props: {
    MuiRadio: {
      color: 'primary'
    },
    MuiUseMediaQuery: {
      noSsr: true
    },
    MuiAlert: MuiAlertProps,
    MuiDialogTitle: MuiDialogTitleProps,
    MuiDialog: MuiDialogProps
  }
});

export default theme;
