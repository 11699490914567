import React from 'react';
import PropTypes from 'prop-types';
import { useMachine } from '@xstate/react';
import {
  BrowserRouter,
  Switch,
  useLocation,
  useHistory
} from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { isEmbededDriverApp } from 'shared/utils/driver-app';
import InitializationTemplate from 'view/templates/initialization';
import Alert from 'view/atoms/alert';
import ErrorTracker from 'view/pages/error-tracker';
import orchestratorMachine from 'machines/orchestrator.machine';
import PAGES, { paths } from './pages';
import { createRouteFactory, getMachineByPath } from './utils';

export const Routes = ({
  machine,
  pages,
  skipCognitoAuthentication,
  authorizedRedirectPath,
  unauthorizedRedirectPath
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isDev = process.env.NODE_ENV !== 'production';
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [current, send] = useMachine(
    machine.withContext({
      searchParams,
      ...machine.context
    }),
    { devTools: isDev }
  );
  const { pageName, pageEvent } = getMachineByPath(location.pathname, pages);

  const { context, event, matches } = current;
  const { isAuthenticated, notification, driver } = context;
  const currentEvent = event.type;
  const { emailIsVerified } = driver;

  // Pages without cognito authentication
  const {
    state: { authenticatedUser, loading: isAuthenticating }
  } = useAmplifyAuth();
  const isCognitoAuthenticated = isAuthenticating || authenticatedUser;
  const publicPages = [...skipCognitoAuthentication, unauthorizedRedirectPath];

  const shoudlRedirect =
    !isCognitoAuthenticated &&
    !isEmbededDriverApp() &&
    !publicPages.includes(location.pathname);

  if (shoudlRedirect) {
    history.push(PAGES.ONBOARDING.path);

    return <></>;
  }

  // Redirect if route don't match
  if (!pageName && !pageEvent) {
    const redirectPath = isAuthenticated
      ? authorizedRedirectPath
      : unauthorizedRedirectPath;

    history.push(redirectPath);

    return <></>;
  }

  const isFromVerificationCodePage =
    history.location.state?.from === paths.verificationCode;

  if (isFromVerificationCodePage) {
    history.push(PAGES.DRIVER_GET_DRIVER.path);

    return <></>;
  }

  if (pageEvent !== 'EMAIL_VERIFICATION' && emailIsVerified === false) {
    history.push(PAGES.EMAIL_VERIFICATION.path, {
      from: paths.documents.hub
    });

    return <></>;
  }

  const handleCloseNotification = () => send('NOTIFICATION.CLOSE');
  const hasNotification = matches('notification.open');
  const isLoading = !matches({ app: pageName });
  const shouldSpawnMachine =
    !matches({ app: 'loading' }) && pageEvent !== currentEvent; // Avoid send duplicated event

  if (shouldSpawnMachine) {
    send(pageEvent);
  }

  if (isLoading) {
    return <InitializationTemplate context={context} />;
  }

  const createRoute = createRouteFactory({
    isAuthenticated,
    authorizedRedirectPath,
    unauthorizedRedirectPath,
    pageProps: {
      context,
      history,
      isDesktop
    }
  });

  return (
    <>
      {hasNotification && (
        <Alert
          message={notification.message}
          startAdornment={notification.startAdornment}
          color={notification.color}
          onClose={handleCloseNotification}
          open
        />
      )}
      {Object.entries(pages).map(createRoute)}
    </>
  );
};

Routes.propTypes = {
  machine: PropTypes.shape({
    withContext: PropTypes.func.isRequired,
    context: PropTypes.shape({}).isRequired
  }).isRequired,
  skipCognitoAuthentication: PropTypes.arrayOf(PropTypes.string),
  unauthorizedRedirectPath: PropTypes.string.isRequired,
  authorizedRedirectPath: PropTypes.string.isRequired,
  pages: PropTypes.objectOf(
    PropTypes.shape({
      component: PropTypes.func,
      path: PropTypes.string,
      machine: PropTypes.objectOf(
        PropTypes.shape({
          type: PropTypes.string,
          context: PropTypes.shape({
            driver: PropTypes.shape({
              userId: PropTypes.string,
              name: PropTypes.string
            }),
            isAuthenticated: PropTypes.bool
          }),
          states: {
            app: PropTypes.shape({})
          },
          actions: PropTypes.shape({})
        })
      ),
      authenticated: PropTypes.bool
    })
  ).isRequired
};

Routes.defaultProps = {
  skipCognitoAuthentication: []
};

const Router = () => (
  <BrowserRouter>
    <Switch>
      <ErrorTracker>
        <Routes
          machine={orchestratorMachine}
          pages={PAGES}
          skipCognitoAuthentication={[PAGES.USER_RESTORE.path]}
          authorizedRedirectPath={PAGES.DOCUMENT_HUB.path}
          unauthorizedRedirectPath={PAGES.ONBOARDING.path}
        />
      </ErrorTracker>
    </Switch>
  </BrowserRouter>
);

export default Router;
