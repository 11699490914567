/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  useAmplifyAuth,
  USER_NOT_AUTHENTICATED_ERROR
} from '@loggi/authentication-lib';
import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary,
  OneTemplateFooter
} from '@loggi/components/src/one/template';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, SvgIcon } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ReactComponent as AppleIcon } from '../../assets/apple-icon.svg';
import { ReactComponent as GoogleIcon } from '../../assets/google-icon.svg';
import ForgotPasswordLink from '../../components/forgot-password-link/forgot-password-link';
import PasswordInput from '../../components/password-input/password-input.component';
import ScreenHeader from '../../components/screen-header.component';
import { FORGOT_PASSWORD_ROUTE, SIGN_UP_ROUTE } from '../../routes/constants';
import { errorHandler, showNotificationMessage } from '../../service';
import {
  APPLE_ICON_ID,
  APPLE_PROVIDER,
  APPLE_SIGN_IN_BUTTON_ID,
  CREATE_ACCOUNT_BUTTON_ID,
  EMAIL_INPUT_ID,
  GOOGLE_ICON_ID,
  GOOGLE_PROVIDER,
  GOOGLE_SIGN_IN_BUTTON_ID,
  LOADING_ID,
  SIGN_IN_BUTTON_ID,
  SIGN_IN_ERRORS,
  LOGGIOPS_EMAIL_REGEX
} from './constants';
import BackgroundSignupPackage1 from '../../assets/background-signup-package-1.svg';
import BackgroundSignupPackage2 from '../../assets/background-signup-package-2.svg';
import BackgroundSignupPackage3 from '../../assets/background-signup-package-3.svg';
import IllustrationSignupMobile from '../../assets/illustration-signup-mobile.svg';
import BackGroundImage from '../../assets/background-image.svg';

const useSigninEnviosStyle = makeStyles(({ typography, palette, spacing }) => ({
  btn: {
    fontSize: typography.pxToRem(14),
    marginTop: typography.pxToRem(20)
  },
  federatedBtn: {
    backgroundColor: 'white',
    marginBottom: typography.pxToRem(15),
    '& svg': {
      marginRight: typography.pxToRem(16)
    }
  },
  input: { marginBottom: typography.pxToRem(15) },
  divider: {
    marginTop: typography.pxToRem(25),
    marginBottom: typography.pxToRem(5)
  },
  dividerText: {
    fontSize: typography.pxToRem(15),
    fontWeight: 'bold',
    textAlign: 'center',
    position: 'relative',
    zIndex: 2,
    '&::after': {
      content: "''",
      position: 'absolute',
      top: '50%',
      left: 0,
      right: 0,
      borderTop: 'solid 1px #EAEEF4',
      zIndex: -1
    }
  },
  dividerLine: {
    margin: 10,
    display: 'inline-block',
    alignItems: 'center',
    padding: `0 ${typography.pxToRem(15)}`,
    background: '#fff'
  },
  createAccountText: {
    textAlign: 'center',
    fontSize: typography.pxToRem(13),
    paddingRight: typography.pxToRem(2)
  },
  loading: {
    color: 'white'
  },

  background: {
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${BackGroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white'
  },
  backgroundMobile: {
    backgroundColor: 'white'
  },
  textHeader: {
    color: palette.common.white,
    marginTop: spacing(-15),
    marginBottom: spacing(13),
    marginLeft: spacing(10)
  },
  textIntroductionMobile: {
    alignItems: 'flex-start',
    margingTop: spacing(5),
    padding: spacing(3)
  },
  packageStatusImage: {
    marginTop: spacing(10),
    marginLeft: spacing(20)
  },
  formSignin: {
    display: 'flex',
    height: '100%',
    marginTop: spacing(-18),
    marginLeft: spacing(40),
    marginRight: spacing(-25)
  }
}));

const EmailInput = ({ handleChange, handleBlur, email, error }) => {
  const styles = useSigninEnviosStyle();
  const { t } = useTranslation('signIn');

  return (
    <TextField
      className={styles.input}
      autoFocus
      error={Boolean(error)}
      name="email"
      helperText={error}
      value={email}
      id={EMAIL_INPUT_ID}
      label={t('email.label')}
      onChange={handleChange}
      onBlur={handleBlur}
      fullWidth
      variant="outlined"
      inputProps={{ autoCapitalize: 'none' }}
    />
  );
};

EmailInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  error: PropTypes.string
};

EmailInput.defaultProps = {
  error: ''
};

export const GoogleSignIn = () => {
  const { state } = useLocation();
  const { federatedSignIn } = useAmplifyAuth();
  const styles = useSigninEnviosStyle();
  const { t } = useTranslation('signIn');

  return (
    <Grid item xs={12}>
      <Button
        variant="contained"
        className={`${styles.btn} ${styles.federatedBtn}`}
        fullWidth
        data-testid={GOOGLE_SIGN_IN_BUTTON_ID}
        onClick={() => federatedSignIn(GOOGLE_PROVIDER, state?.from)}
      >
        <SvgIcon
          component={GoogleIcon}
          viewBox="0 0 600 476.6"
          id={GOOGLE_ICON_ID}
        />
        {t('googleSignInButton.text')}
      </Button>
    </Grid>
  );
};

export const AppleSignIn = () => {
  const { state } = useLocation();
  const { federatedSignIn } = useAmplifyAuth();
  const styles = useSigninEnviosStyle();
  const { t } = useTranslation('signIn');

  return (
    <Grid item xs={12}>
      <Button
        variant="contained"
        className={`${styles.btn} ${styles.federatedBtn}`}
        fullWidth
        data-testid={APPLE_SIGN_IN_BUTTON_ID}
        onClick={() => federatedSignIn(APPLE_PROVIDER, state?.from)}
      >
        <SvgIcon component={AppleIcon} id={APPLE_ICON_ID} />
        {t('appleSignInButton.text')}
      </Button>
    </Grid>
  );
};

const CreateAccount = () => {
  const styles = useSigninEnviosStyle();
  const { push } = useHistory();
  const { t } = useTranslation('signIn');

  return (
    <Grid item>
      <Typography variant="caption" className={styles.createAccountText}>
        {t('dontHaveAccountEnvios.message')}
      </Typography>
      <Link
        className={styles.createAccountText}
        component="button"
        variant="body2"
        data-testid={CREATE_ACCOUNT_BUTTON_ID}
        onClick={() => push(SIGN_UP_ROUTE)}
      >
        {t('createAccountEnvios.text')}
      </Link>
    </Grid>
  );
};

export const Divider = () => {
  const styles = useSigninEnviosStyle();
  const { t } = useTranslation('signIn');

  return (
    <Grid item className={styles.divider}>
      <Typography variant="h1" className={styles.dividerText}>
        <span className={styles.dividerLine}>{t('divider.text')}</span>
      </Typography>
    </Grid>
  );
};

const SigninEnvios = ({
  showForgotPassword,
  showAppleSignIn,
  showGoogleSignIn,
  showCreateAccount,
  appVersionComponent
}) => {
  const styles = useSigninEnviosStyle();
  const {
    signIn,
    state: { error: stateError }
  } = useAmplifyAuth();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('signIn');
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const [isPasswordInputVisible, setIsPasswordInputVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const history = useHistory();
  const [userEmail, setUserEmail] = useState('');
  const styleBackground = isMobile
    ? styles.backgroundMobile
    : styles.background;

  const emailSchemaObject = Yup.string()
    .required(t('emailIsRequired.message'))
    .email(t('invalidEmail.message'))
    .matches(RegExp(LOGGIOPS_EMAIL_REGEX), t('invalidLoggiOpsEmail.message'));

  const SIGN_IN_SCHEMA = Yup.object().shape({
    email: emailSchemaObject
  });

  const SIGN_IN_SCHEMA_PASSWORD_VISIBLE = Yup.object().shape({
    email: emailSchemaObject,
    password: Yup.string().required(t('passwordIsRequired.message'))
  });

  const validationSchema = isPasswordInputVisible
    ? SIGN_IN_SCHEMA_PASSWORD_VISIBLE
    : SIGN_IN_SCHEMA;

  const errorNotification = useCallback(
    error => {
      const { NotAuthorizedException, UserNotFoundException } = SIGN_IN_ERRORS;
      const {
        message: { code, message, name }
      } = error;

      if (code === NotAuthorizedException.code) {
        return NotAuthorizedException[message]
          ? t(
              `signIn:errorsMessages.NotAuthorizedException.${
                NotAuthorizedException[message]
              }`
            )
          : t('signIn:errorsMessages.NotAuthorizedException.fallbackMessage');
      }
      if (code === UserNotFoundException.code) {
        return UserNotFoundException[message]
          ? t(
              `signIn:errorsMessages.UserNotFoundException.${
                UserNotFoundException[message]
              }`
            )
          : t('signIn:errorsMessages.UserNotFoundException.fallbackMessage');
      }

      return t(`signIn:errorsMessages.${SIGN_IN_ERRORS[name].code}`);
    },
    [t]
  );

  const signInErrorHandler = signInError => {
    const {
      message: { code, name }
    } = signInError;
    const isKnownError = Boolean(SIGN_IN_ERRORS[name]);

    if (!isKnownError) {
      errorHandler(signInError);
      showNotificationMessage(
        t('signIn:errorsMessages.DefaultException'),
        'error',
        enqueueSnackbar
      );
      setLoading(false);
      return setUserEmail('');
    }

    const { PasswordResetRequiredException } = SIGN_IN_ERRORS;
    if (code === PasswordResetRequiredException.code) {
      return history.push({
        pathname: FORGOT_PASSWORD_ROUTE,
        state: {
          email: userEmail,
          translationNamespace: 'resetPasswordRequiredForm'
        }
      });
    }

    const errorFeedback = errorNotification(signInError);
    showNotificationMessage(errorFeedback, 'error', enqueueSnackbar);
    setLoading(false);
    return setUserEmail('');
  };

  useEffect(() => {
    if (state?.invalidSignUp) {
      showNotificationMessage(
        t('signUpUserAlreadyExistsError.message'),
        'error',
        enqueueSnackbar
      );
      history.replace(history.location.pathname, { invalidSignUp: false });
    }
  }, [state, enqueueSnackbar, t, history]);

  useEffect(() => {
    if (
      stateError &&
      stateError !== USER_NOT_AUTHENTICATED_ERROR &&
      userEmail
    ) {
      signInErrorHandler(stateError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateError, userEmail]);

  const validateAccount = formValues => {
    const { email, password } = formValues;

    if (email && password) {
      /**
       * We don't redirect to /home here because App.js take
       * care of the route logic for us.
       */
      setLoading(true);
      setUserEmail(email);
      signIn(email, password, state?.from);
    } else if (email) {
      setIsPasswordInputVisible(true);
    }
  };

  const handleFillPassword = e => {
    if (e.target.value) {
      setIsPasswordInputVisible(true);
    }
  };

  return (
    <>
      <Box className={styleBackground}>
        <OneTemplate enableFooter={false}>
          <OneTemplateSummary color="transparente" />
          <OneTemplateContent display="flex" height="100%" disablePaper>
            {isMobile ? (
              <Box mt={{ xs: -10, md: -20, sm: -20 }}>
                <Box p={3}>
                  <Formik
                    validateOnBlur={false}
                    initialValues={{
                      email: '',
                      password: ''
                    }}
                    onSubmit={validateAccount}
                    validationSchema={validationSchema}
                  >
                    {({
                      handleChange,
                      handleSubmit,
                      handleBlur,
                      values,
                      errors,
                      touched
                    }) => (
                      <form
                        onSubmit={e => {
                          e.preventDefault();
                          handleSubmit();
                        }}
                      >
                        <Box m={5}>
                          <Box ml={-5}>
                            <Typography variant="h4">
                              <strong>{t('form.titleFirst')}</strong>
                            </Typography>
                            <Typography variant="h4">
                              <strong>{t('form.titleSecond')}</strong>
                            </Typography>
                            <Box mt={1}>
                              <Typography>{t('form.subtitle')}</Typography>
                            </Box>
                          </Box>
                        </Box>

                        <EmailInput
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          email={values.email}
                          error={touched.email && errors.email}
                        />
                        <Box
                          width="100%"
                          display={isPasswordInputVisible ? 'block' : 'none'}
                        >
                          <PasswordInput
                            handleChange={handleChange}
                            password={values.password}
                            handleBlur={handleBlur}
                            error={touched.password && errors.password}
                            handleFillPassword={handleFillPassword}
                            label={t('password.label')}
                          />
                          {showForgotPassword && (
                            <ForgotPasswordLink email={values.email} />
                          )}
                        </Box>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={loading}
                            type="submit"
                            className={styles.btn}
                            fullWidth
                            data-testid={SIGN_IN_BUTTON_ID}
                          >
                            {!loading && t('signInButton.text')}
                            {loading && (
                              <CircularProgress
                                data-testid={LOADING_ID}
                                className={styles.loading}
                                size={26}
                              />
                            )}
                          </Button>
                        </Grid>
                        {(showGoogleSignIn || showAppleSignIn) && <Divider />}
                        {showGoogleSignIn && <GoogleSignIn />}
                        {showAppleSignIn && <AppleSignIn />}
                        <Box mt={2} align="center">
                          {showCreateAccount && <CreateAccount />}
                          {appVersionComponent}
                        </Box>
                      </form>
                    )}
                  </Formik>
                  <Box mt={10}>
                    <OneTemplateFooter />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box className={styles.textIntroductionMobile} mt={8}>
                    <Box>
                      <ScreenHeader title={t('headerContent.first')} />
                    </Box>
                    <Box>
                      <Typography variant="h4">
                        <strong>{t('headerContent.second')}</strong>
                      </Typography>
                    </Box>

                    <Box mt={3}>
                      <Typography>
                        <strong>{t('headerContent.third')}</strong>
                      </Typography>
                      <Typography>
                        <strong>{t('headerContent.fourth')}</strong>
                      </Typography>
                    </Box>

                    <Box mt={3}>
                      <Typography> {t('headerContent.fifth')} </Typography>
                      <Typography> {t('headerContent.sixth')} </Typography>
                    </Box>
                    <Box mt={3}>
                      <Typography> {t('headerContent.seventh')} </Typography>
                      <Typography> {t('headerContent.eighth')} </Typography>
                      <Typography> {t('headerContent.ninth')} </Typography>
                    </Box>
                    <Box p={2} align="center">
                      <img
                        src={IllustrationSignupMobile}
                        alt="illustration signin"
                        data-testid="mobile-img"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Grid container>
                <Grid item sm={6}>
                  <Box className={styles.textHeader}>
                    <Box>
                      <ScreenHeader title={t('headerContent.first')} />
                    </Box>
                    <Box mt={-1}>
                      <Typography variant="h4">
                        <strong>{t('headerContent.second')}</strong>
                      </Typography>
                    </Box>

                    <Box mt={3}>
                      <Typography>
                        <strong>{t('headerContent.third')}</strong>
                      </Typography>
                      <Typography>
                        <strong>{t('headerContent.fourth')}</strong>
                      </Typography>
                    </Box>

                    <Box mt={3}>
                      <Typography> {t('headerContent.fifth')} </Typography>
                      <Typography> {t('headerContent.sixth')} </Typography>
                    </Box>
                    <Box mt={3}>
                      <Typography> {t('headerContent.seventh')} </Typography>
                      <Typography> {t('headerContent.eighth')} </Typography>
                      <Typography> {t('headerContent.ninth')} </Typography>
                    </Box>
                  </Box>

                  <Box className={styles.packageStatusImage}>
                    <Box mb={-5}>
                      <img
                        src={BackgroundSignupPackage1}
                        alt="package status illustration"
                      />
                    </Box>
                    <Box mb={-5} ml={-7}>
                      <img
                        src={BackgroundSignupPackage2}
                        alt="package status illustration"
                      />
                    </Box>
                    <Box>
                      <img
                        src={BackgroundSignupPackage3}
                        alt="package status illustration"
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item md={4} lg={6}>
                  <Box className={styles.formSignin}>
                    <Box>
                      <Formik
                        validateOnBlur={false}
                        initialValues={{
                          email: '',
                          password: ''
                        }}
                        onSubmit={validateAccount}
                        validationSchema={validationSchema}
                      >
                        {({
                          handleChange,
                          handleSubmit,
                          handleBlur,
                          values,
                          errors,
                          touched
                        }) => (
                          <form
                            onSubmit={e => {
                              e.preventDefault();
                              handleSubmit();
                            }}
                          >
                            <Grid
                              container
                              alignContent="space-between"
                              spacing={1}
                            >
                              <Box m={4} ml={0}>
                                <Box>
                                  <Typography variant="h4">
                                    <strong>{t('form.titleFirst')}</strong>
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography variant="h4">
                                    <strong>{t('form.titleSecond')}</strong>
                                  </Typography>
                                </Box>
                                <Box mt={1}>
                                  <Typography>{t('form.subtitle')}</Typography>
                                </Box>
                              </Box>

                              <EmailInput
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                email={values.email}
                                error={touched.email && errors.email}
                              />
                              <Box
                                width="100%"
                                display={
                                  isPasswordInputVisible ? 'block' : 'none'
                                }
                              >
                                <PasswordInput
                                  handleChange={handleChange}
                                  password={values.password}
                                  handleBlur={handleBlur}
                                  error={touched.password && errors.password}
                                  handleFillPassword={handleFillPassword}
                                  label={t('password.label')}
                                />
                                {showForgotPassword && (
                                  <ForgotPasswordLink email={values.email} />
                                )}
                              </Box>
                              <Box pt={2} width="100%">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disabled={loading}
                                  type="submit"
                                  className={styles.btn}
                                  fullWidth
                                  data-testid={SIGN_IN_BUTTON_ID}
                                >
                                  {!loading && t('signInButton.text')}
                                  {loading && (
                                    <CircularProgress
                                      data-testid={LOADING_ID}
                                      className={styles.loading}
                                      size={26}
                                    />
                                  )}
                                </Button>
                              </Box>
                              {(showGoogleSignIn || showAppleSignIn) && (
                                <Box align="center" width="100%">
                                  {' '}
                                  <Divider />{' '}
                                </Box>
                              )}
                              {showGoogleSignIn && <GoogleSignIn />}
                              {showAppleSignIn && <AppleSignIn />}
                              <Box mt={2} align="center" width="100%">
                                {showCreateAccount && <CreateAccount />}
                                {appVersionComponent}
                              </Box>
                            </Grid>
                          </form>
                        )}
                      </Formik>
                      <Box mt={20}>
                        <OneTemplateFooter />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </OneTemplateContent>
        </OneTemplate>
      </Box>
    </>
  );
};

SigninEnvios.propTypes = {
  showForgotPassword: PropTypes.bool,
  showAppleSignIn: PropTypes.bool,
  showGoogleSignIn: PropTypes.bool,
  showCreateAccount: PropTypes.bool,
  appVersionComponent: PropTypes.element
};

SigninEnvios.defaultProps = {
  showForgotPassword: true,
  showAppleSignIn: true,
  showGoogleSignIn: true,
  showCreateAccount: true,
  appVersionComponent: null
};

export default SigninEnvios;
