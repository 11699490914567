import { Machine, assign, sendParent } from 'xstate';
import { getDriverDocuments } from 'infra/services/driver-documents';

export const STATES = {
  loading: 'LOADING',
  success: 'SUCCESS',
  error: 'ERROR',
  retry: 'RETRY'
};

const getDocuments = context => {
  return getDriverDocuments(context.driver.userId);
};

const services = {
  getDocuments
};

export const actions = {
  setDriverDocuments: assign((_, { data }) => ({
    operationalStatus: data.driverOperationalStatus,
    documents: data.documentSummary,
    hasAlert: data.hasAlert
  })),
  sendDocuments: sendParent((_, { data }) => ({
    type: 'SET_DOCUMENTS',
    data: { documents: data.documentSummary }
  }))
};

const DocumentHubMachine = Machine(
  {
    id: 'get status of all documents',
    initial: STATES.loading,
    context: {
      userId: null,
      documents: [],
      operationalStatus: null,
      hasAlert: false,
      errors: []
    },
    states: {
      [STATES.loading]: {
        on: {
          SUCCESS: STATES.success,
          FAILURE: STATES.failure
        },
        invoke: {
          id: 'getDocuments',
          src: 'getDocuments',
          onDone: {
            target: STATES.success,
            actions: ['setDriverDocuments', 'sendDocuments']
          },
          onError: {
            target: STATES.error,
            actions: [
              assign({
                errors: (_, { data }) => {
                  return data.errors;
                }
              })
            ]
          }
        }
      },
      [STATES.success]: {
        type: 'final'
      },
      [STATES.error]: {
        on: {
          [STATES.retry]: STATES.loading
        }
      }
    }
  },
  {
    services,
    actions
  }
);

export default DocumentHubMachine;
