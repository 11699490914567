export const TEXT = {
  TITLE: 'Informe o seu MEI',
  SUBTITLE:
    'Você precisa ter um CNPJ no seu nome com um CNAE aceito para entrega.',
  INVALID_CNPJ_TITLE: 'Corrija seu MEI',
  INVALID_CNPJ_SUBTITLE: 'Seu CNPJ foi rejeitado porque: ',
  INVALID_CNPJ_SUBTITLE_NO_REASON: 'Seu CNPJ foi rejeitado. ',
  INVALID_CNPJ_TEXT: 'Informe um documento válido pra gente.',
  FORM: {
    CNPJ: 'CNPJ',
    SEND: 'Enviar'
  },
  MORE_INFO: 'Saiba mais',
  OK_GOT_IT: 'Ok, entendi',
  INVALID: 'INVALID',
  PENDING: 'PENDING'
};

export const LIST_OF_ACCEPTED_CNAES = [
  [
    '4930-2/01',
    'Transporte rodoviário de carga, exceto produtos perigosos e mudanças. Municipal.'
  ],
  [
    '4930-2/02',
    'Transporte rodoviário de carga, exceto produtos perigosos e mudanças. Intermunicipal, interestadual e internacional.'
  ],
  ['5320-2/01', 'Serviços de malote não realizados pelo correio nacional.'],
  ['5320-2/02', 'Serviços de entrega rápida.'],
  [
    '5229-0/99',
    'Outras atividades auxiliares dos transportes terrestres não especificadas anteriormente.'
  ]
];
