import {
  AmplifyAuthProvider,
  AmplifyConfigure
} from '@loggi/authentication-lib';

import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';

import registerServiceWorker from './serviceWorkerRegistration';
import CredentialsProvider from './credentials-provider';
import App from './App';
import config from './aws-exports';

import 'operations/config/sentry';

AmplifyConfigure(config);

ReactDOM.render(
  <AmplifyAuthProvider>
    <CredentialsProvider>
      <SnackbarProvider
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top'
        }}
        autoHideDuration={6000}
        maxSnack={3}
      >
        <App />
      </SnackbarProvider>
    </CredentialsProvider>
  </AmplifyAuthProvider>,
  document.getElementById('root')
);

registerServiceWorker();
