import { Machine, assign } from 'xstate';
import * as yup from 'yup';

import { DRIVER_INFO_FORM_MESSAGES } from './utils/errors-message';
import {
  emptyField,
  formValid,
  formInvalid,
  changeField,
  validateFields,
  initialContextFromFieldsValues,
  fieldsValuesFromContext,
  sendErrorNotification,
  sendSuccessNotification,
  FORM_STATE,
  FORM_ACTIONS
} from './utils/form';
import isMobilePhoneValid from './utils/form/validators/mobile-phone';
import {
  mobilePhoneFormatter,
  removeMobilePhoneFormatter
} from './utils/form/formatters/mobile-phone';

import {
  updateDriverInfo,
  getUserRestoreInfo
} from '../infra/services/user-restore';

const userInfoSchema = yup.object().shape({
  fullName: yup
    .string()
    .test(
      'fullName',
      DRIVER_INFO_FORM_MESSAGES.name,
      val => val && val.split(' ').length > 1
    )
    .required(DRIVER_INFO_FORM_MESSAGES.name),
  email: yup
    .string()
    .email(DRIVER_INFO_FORM_MESSAGES.email)
    .required(DRIVER_INFO_FORM_MESSAGES.emailEmpty),
  mobile: yup
    .string()
    .test('mobile', DRIVER_INFO_FORM_MESSAGES.mobileNumber, isMobilePhoneValid)
    .required(DRIVER_INFO_FORM_MESSAGES.mobileNumberEmpty)
    .transform(mobilePhoneFormatter),
  terms: yup.boolean().oneOf([true], DRIVER_INFO_FORM_MESSAGES.terms)
});

export const guards = {
  formValid,
  formInvalid,
  tokenInvalid: context => context.tokenInvalid
};

const FORM_NOTIFICATIONS = {
  sucess:
    'Beleza, cadastro feito. Agora é só baixar o app Loggi para Entregadores',
  error: 'Falha no sistema. Tente de novo.',
  invalidToken: `
    Eita, o link de cadastro expirou. 
    Peça para fazerem seu cadastro novamente na plataforma de gestão.
  `
};

export const actions = {
  changeField,
  validateFields: validateFields(userInfoSchema),
  sendErrorNotification: sendErrorNotification(FORM_NOTIFICATIONS.error, {
    getErrorFromEvent: true
  }),
  sendSuccessNotification: sendSuccessNotification(FORM_NOTIFICATIONS.sucess),
  sendInvalidTokenNotification: sendErrorNotification(
    FORM_NOTIFICATIONS.invalidToken
  )
};

const services = {
  updateDriverInfo: ctx => {
    const { fullName, email, mobile } = fieldsValuesFromContext(ctx);

    const payload = {
      fullName,
      email,
      mobile: removeMobilePhoneFormatter(mobile)
    };

    return updateDriverInfo(ctx, payload);
  },
  getUserRestoreInfo
};

const initialContext = ({ lastMileCompanyName, email, fullName, mobile }) => ({
  lastMileCompanyName,
  tokenInvalid: false,
  firstName: fullName && fullName.split(' ')[0],
  fields: {
    fullName: emptyField(fullName),
    email: emptyField(email),
    mobile: emptyField(mobile),
    terms: emptyField(false)
  },
  errors: []
});

const UserRestoreMachine = Machine(
  {
    id: 'user restore',
    initial: FORM_STATE.loading,
    context: initialContextFromFieldsValues(initialContext({}), userInfoSchema),
    states: {
      [FORM_STATE.loading]: {
        invoke: {
          id: 'getUserRestoreInfo',
          src: 'getUserRestoreInfo',
          onDone: {
            target: FORM_STATE.editing,
            actions: [
              assign((_, { data }) =>
                initialContextFromFieldsValues(
                  initialContext(data),
                  userInfoSchema
                )
              )
            ]
          },
          onError: {
            target: FORM_STATE.error,
            actions: [
              assign({
                tokenInvalid: (_, { data }) => {
                  return data.code === 401;
                }
              })
            ]
          }
        }
      },
      [FORM_STATE.editing]: {
        initial: FORM_STATE.invalid,
        states: {
          [FORM_STATE.invalid]: {
            on: {
              '': [{ target: FORM_STATE.valid, cond: 'formValid' }]
            }
          },
          [FORM_STATE.valid]: {
            on: {
              '': [{ target: FORM_STATE.invalid, cond: 'formInvalid' }]
            }
          }
        },
        on: {
          [FORM_ACTIONS.submit]: {
            target: FORM_STATE.submitting,
            cond: 'formValid'
          },
          [FORM_ACTIONS.change]: {
            actions: ['changeField', 'validateFields']
          }
        }
      },
      [FORM_STATE.submitting]: {
        invoke: {
          id: 'updateDriverInfo',
          src: 'updateDriverInfo',
          onDone: {
            target: FORM_STATE.success,
            actions: 'sendSuccessNotification'
          },
          onError: {
            target: FORM_STATE.editing,
            actions: ['sendErrorNotification']
          }
        }
      },
      [FORM_STATE.success]: {
        type: 'final'
      },
      [FORM_STATE.error]: {
        on: {
          [FORM_ACTIONS.retry]: FORM_STATE.loading,
          '': { target: FORM_STATE.failure, cond: 'tokenInvalid' }
        }
      },
      [FORM_STATE.failure]: {
        entry: ['sendInvalidTokenNotification']
      }
    }
  },
  {
    guards,
    actions,
    services
  }
);

export default UserRestoreMachine;
