import {
  useAmplifyAuth,
  USER_NOT_AUTHENTICATED_ERROR
} from '@loggi/authentication-lib';
import OneTemplate, {
  OneTemplateContent,
  OneTemplateSummary,
  OneTemplateFooter
} from '@loggi/components/src/one/template';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { GoogleSignIn, AppleSignIn, Divider } from '../signin/signin-envios';
import PasswordInput from '../../components/password-input/password-input.component';
import ScreenHeader from '../../components/screen-header.component';
import { errorHandler, showNotificationMessage } from '../../service';
import { SIGN_IN_ERRORS } from '../signin/constants';
import {
  CONTINUE_BUTTON_ID,
  EMAIL_INPUT_BUTTON_ID,
  LOADING_ID,
  NAME_INPUT_BUTTON_ID,
  SIGNUP_ERRORS
} from './constants';
import { LOGIN_ROUTE } from '../../routes/constants';
import BackGroundForm from '../../assets/background-image.svg';
import BackgroundSignupPackage1 from '../../assets/background-signup-package-1.svg';
import BackgroundSignupPackage2 from '../../assets/background-signup-package-2.svg';
import BackgroundSignupPackage3 from '../../assets/background-signup-package-3.svg';
import SignupFormMobile from './sign-up-form-mobile.component';
import {
  signupValidationSchema,
  _functionGetErrorFeedback,
  _functionSignInErrorNotification,
  _functionShowDefaultErrorNotificationMessage
} from '../utils';

const initialValues = {
  email: '',
  name: '',
  password: ''
};

const useSignupFormStyle = makeStyles(({ palette, spacing, typography }) => ({
  progress: {
    color: palette.common.white
  },
  background: {
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${BackGroundForm})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white'
  },
  backgroundMobile: {
    backgroundColor: 'white'
  },
  packageStatusImage: {
    marginTop: spacing(10),
    marginLeft: spacing(20)
  },
  formSignup: {
    display: 'flex',
    height: '100%',
    marginTop: spacing(-18),
    marginLeft: spacing(40),
    marginRight: spacing(-25)
  },
  labels: {
    marginBottom: spacing(1)
  },
  textHeader: {
    color: palette.common.white,
    marginTop: spacing(-15),
    marginBottom: spacing(13),
    marginLeft: spacing(10)
  },
  textHeaderMobile: {
    align: 'flex-start',
    margingTop: spacing(-10),
    marginBottom: spacing(5),
    padding: spacing(3)
  },
  registeLink: {
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  headerMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  haveAccountText: {
    textAlign: 'center',
    fontSize: typography.pxToRem(14),
    paddingRight: typography.pxToRem(2)
  }
}));

const SignupForm = ({ showAppleSignIn, showGoogleSignIn }) => {
  const {
    signUp,
    state: { error: stateError }
  } = useAmplifyAuth();
  const styles = useSignupFormStyle();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['signupForm', 'signIn']);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const styleBackground = isMobile
    ? styles.backgroundMobile
    : styles.background;

  const signInErrorNotification = useCallback(
    signInError =>
      _functionSignInErrorNotification(signInError, errorText => t(errorText)),
    [t]
  );

  const onSubmitSignUp = (formValues, { setSubmitting }) => {
    setLoading(true);
    const { email, name, password } = formValues;

    setSubmitting(false);

    signUp(email.toLowerCase(), name.trim(), password);
  };

  const getErrorFeedback = useCallback(
    error =>
      _functionGetErrorFeedback(
        error,
        errorText => t(errorText),
        signInErrorNotification
      ),
    [signInErrorNotification, t]
  );

  const showDefaultErrorNotificationMessage = useCallback(
    error =>
      _functionShowDefaultErrorNotificationMessage(
        error,
        errorText => t(errorText),
        enqueueSnackbar
      ),
    [enqueueSnackbar, t]
  );

  useEffect(() => {
    if (stateError && stateError !== USER_NOT_AUTHENTICATED_ERROR) {
      const isKnownError = Boolean(
        SIGNUP_ERRORS[(stateError.message?.name)] ||
          SIGN_IN_ERRORS[(stateError.message?.name)]
      );

      if (isKnownError) {
        const errorFeedback = getErrorFeedback(stateError);
        showNotificationMessage(errorFeedback, 'error', enqueueSnackbar);
      } else {
        errorHandler(stateError);
        showDefaultErrorNotificationMessage(stateError);
      }

      setLoading(false);
    }
  }, [
    stateError,
    getErrorFeedback,
    enqueueSnackbar,
    t,
    showDefaultErrorNotificationMessage
  ]);

  const formik = useFormik({
    initialValues,
    onSubmit: onSubmitSignUp,
    validationSchema: signupValidationSchema
  });
  const {
    handleSubmit,
    setFieldTouched,
    handleChange,
    handleBlur,
    values,
    errors,
    touched
  } = formik;

  return (
    <>
      <Box className={styleBackground}>
        <OneTemplate enableFooter={false}>
          <OneTemplateSummary color="transparente" />

          <OneTemplateContent display="flex" height="100%" disablePaper>
            {isMobile ? (
              <SignupFormMobile />
            ) : (
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Box className={styles.textHeader}>
                    <Box>
                      <ScreenHeader title={t('headerContent.first')} />
                    </Box>
                    <Box mt={-1}>
                      <Typography variant="h4">
                        <strong>{t('headerContent.second')}</strong>
                      </Typography>
                    </Box>

                    <Box mt={3}>
                      <Typography>
                        <strong>{t('headerContent.third')}</strong>
                      </Typography>
                      <Typography>
                        <strong>{t('headerContent.fourth')}</strong>
                      </Typography>
                    </Box>

                    <Box mt={3}>
                      <Typography> {t('headerContent.fifth')} </Typography>
                      <Typography> {t('headerContent.sixth')} </Typography>
                    </Box>
                    <Box mt={3}>
                      <Typography> {t('headerContent.seventh')} </Typography>
                      <Typography> {t('headerContent.eighth')} </Typography>
                      <Typography> {t('headerContent.ninth')} </Typography>
                    </Box>
                  </Box>

                  <Box className={styles.packageStatusImage}>
                    <Box mb={-5}>
                      <img
                        src={BackgroundSignupPackage1}
                        alt="package status illustration"
                      />
                    </Box>
                    <Box mb={-5} ml={-7}>
                      <img
                        src={BackgroundSignupPackage2}
                        alt="package status illustration"
                      />
                    </Box>
                    <Box>
                      <img
                        src={BackgroundSignupPackage3}
                        alt="package status illustration"
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item sm={6} p={2}>
                  <Box className={styles.formSignup}>
                    <Box>
                      <FormikProvider value={formik}>
                        <form
                          onSubmit={e => {
                            e.preventDefault();
                            handleSubmit();
                          }}
                          data-testid="sign-up-form"
                        >
                          <Box m={3} ml={0}>
                            <Box>
                              <Typography variant="h4">
                                <strong>{t('signupForm.titleFirst')}</strong>
                              </Typography>
                              <Typography variant="h4">
                                <strong>{t('signupForm.titleSecond')}</strong>
                              </Typography>
                            </Box>
                            <Box mt={1} mb={-2}>
                              <Typography>
                                {t('signupForm.subtitleFirst')}
                              </Typography>
                              <Typography>
                                {t('signupForm.subtitleSecond')}
                              </Typography>
                            </Box>
                          </Box>
                          <TextField
                            id={NAME_INPUT_BUTTON_ID}
                            margin="normal"
                            name="name"
                            onChange={handleChange}
                            onBlur={() => setFieldTouched('name')}
                            fullWidth
                            label={t('inputsLabel.name')}
                            variant="outlined"
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                            className={styles.labels}
                          />

                          <TextField
                            id={EMAIL_INPUT_BUTTON_ID}
                            margin="normal"
                            onChange={handleChange}
                            onBlur={() => setFieldTouched('email')}
                            name="email"
                            fullWidth
                            label={t('inputsLabel.email')}
                            variant="outlined"
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                            className={styles.labels}
                          />

                          <PasswordInput
                            handleChange={handleChange}
                            password={values.password}
                            handleBlur={handleBlur}
                            error={touched.password && errors.password}
                            label={t('inputsLabel.password')}
                            className={styles.labels}
                          />

                          <Box pt={2} width="100%">
                            <Button
                              color="primary"
                              disabled={loading}
                              data-testid={CONTINUE_BUTTON_ID}
                              fullWidth
                              variant="contained"
                              type="submit"
                            >
                              {!loading && t('buttons.continue')}
                              {loading && (
                                <CircularProgress
                                  data-testid={LOADING_ID}
                                  className={styles.progress}
                                  size={26}
                                />
                              )}
                            </Button>
                          </Box>

                          {(showGoogleSignIn || showAppleSignIn) && <Divider />}
                          {showGoogleSignIn && <GoogleSignIn />}
                          {showAppleSignIn && <AppleSignIn />}
                          <Box margin={2}>
                            <Typography
                              align="center"
                              className={styles.haveAccountText}
                            >
                              {t('signupForm.alreadyRegistered')}
                              <Link href={LOGIN_ROUTE}>
                                <styles className={styles.haveAccountText}>
                                  {t('signupForm.loginLink')}
                                </styles>
                              </Link>
                            </Typography>
                          </Box>
                        </form>
                      </FormikProvider>
                      <Box mt={5}>
                        <OneTemplateFooter />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </OneTemplateContent>
        </OneTemplate>
      </Box>
    </>
  );
};
SignupForm.propTypes = {
  showAppleSignIn: PropTypes.bool,
  showGoogleSignIn: PropTypes.bool
};

SignupForm.defaultProps = {
  showAppleSignIn: true,
  showGoogleSignIn: true
};

export default SignupForm;
