import deviceValidation from 'infra-operations/services/device-validation';
import SmsVerificationCodePage from 'view/pages/sms-verification-code';
import { onboardingPageTexts } from 'view/pages/onboarding/constants';
import DeviceValidationError, {
  deviceRegistrationErrorsDefault,
  deviceRegistrationErrorsMap
} from 'view/pages/device-validation-error';
import { forbiddenDevicePageTexts } from 'view/pages/device-validation-error/forbidden';
import { maxDevicesPageTexts } from 'view/pages/device-validation-error/max-devices';
import { alreadyRegisteredPageTexts } from 'view/pages/device-validation-error/already-registered';
import DriverAuthenticationRoutes from './Auth-app';

export default DriverAuthenticationRoutes;
export {
  SmsVerificationCodePage,
  onboardingPageTexts,
  DeviceValidationError,
  deviceRegistrationErrorsDefault,
  deviceRegistrationErrorsMap,
  forbiddenDevicePageTexts,
  maxDevicesPageTexts,
  alreadyRegisteredPageTexts,
  deviceValidation
};
